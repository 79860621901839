import { useDispatch, useSelector } from "react-redux";
import { IWorkgroup } from "../../Models/IWorkgroup";
import "./WorkGroupPickerPage.scss";
import { GlobalState } from "../../Redux/RootReducer";
import {
  Checkbox,
  ISelectableOption,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import CenterPad from "../../Components/CenterPad/CenterPad";
import { useEffect, useState } from "react";
import ApiService from "../../Services/ApiService";
import { IAPIResponse } from "../../Services/AjaxService";
import { ToastMessage, ToastMessageUnique } from "../../Utils/UIMessages";
import { GenericActions } from "../../Redux/Generic/GenericAction";
import Tr from "../../Utils/Translations/Translations";
import Loadable from "../../Components/Loadable/Loadable";
import SmartModal, {
  DismissModal,
  SummonModal,
} from "../../Components/SmartModal/SmartModal";
import StorePage from "../StorePage/StorePage";
import MultiForm, {
  triggerFormValidation,
} from "../../Components/MultiForm/MultiForm";
import ImagePicker from "../../Components/ImagePicker/ImagePicker";
import { ISubscription } from "../../Models/ISubscription";
import { IPurchase } from "../../Models/IPurchase";
import { Link, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import CustomTooltip from "../../Components/CustomTooltip/CustomTooltip";
import Tools from "../../Utils/Tools";
import { IUser } from "../../Models/IUser";
import { mobileCheck } from "../../App";
import StatisticPage from "../StatisticPage/StatisticPage";
import ImageAssetLoader, {
  GenerateAssetUrl,
} from "../../Components/ImageAssetLoader/ImageAssetLoader";
import FeedbackHandler from "../../Components/FeedbackHandler/FeedbackHandler";

const WorkGroupPickerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workgroups: IWorkgroup[] = useSelector(
    (state: GlobalState) => state.generic.workgroups
  );

  const subscriptions: ISubscription[] = useSelector(
    (state: GlobalState) => state.generic.subscriptions
  );
  const purchases: IPurchase[] = useSelector(
    (state: GlobalState) => state.generic.purchases
  );
  const loggedUser: IUser | undefined = useSelector(
    (state: GlobalState) => state.generic.user
  );
  const [acceptBookRemoval, setAcceptBookRemoval] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [toEditWorkgroup, setToEditWorkgroup] = useState<
    IWorkgroup | undefined
  >();
  const [imgUrl, setImgUrl] = useState<string>("");

  const loadWorkGroups = () => {
    setLoading(true);
    ApiService.WorkGroupController.GetWorkgroups(
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.SetWorkgroups(response.parsed));
        }
        setLoading(false);
      }
    );
  };

  const getPurchaseShortIdentifier = (purchase: IPurchase) => {
    let subscription: ISubscription | undefined = (subscriptions ?? []).find(
      (x: ISubscription) => x.Id === purchase?.SubscriptionID
    );

    return (
      Tr.Translate("subscription", subscription?.SubscriptionName) +
      " " +
      `(id:${purchase.ExternalPaymentSystemPurchaseID.trim()})`
    );
  };

  const getPurchasePublicIdentifier = (
    purchaseId: number | string | undefined
  ) => {
    if (purchaseId) {
      let purchase: IPurchase | undefined = purchases.find(
        (x: IPurchase) => x.Id === +purchaseId
      );

      if (purchase) {
        let subscription: ISubscription | undefined = subscriptions.find(
          (x: ISubscription) => x.Id === purchase?.SubscriptionID
        );

        let purchaseExpireDt = new Date(purchase.ExpiresOn ?? "");
        let purchaseExpireText =
          Tr.Translate("language", "purchase_expire_on") +
          " " +
          purchaseExpireDt.toLocaleDateString() +
          " " +
          purchaseExpireDt.toLocaleTimeString();
        let purchaseCreatedDt = new Date(purchase.CreateDT ?? "");

        if (purchase.PaymentPending && purchase.TrialStillValid) {
          purchaseExpireDt = (purchaseCreatedDt as any).addDays(
            purchase.TrialDays
          );
          purchaseExpireText =
            Tr.Translate("language", "trial_ends_on") +
            " " +
            purchaseExpireDt.toLocaleDateString() +
            " " +
            purchaseExpireDt.toLocaleTimeString();
        }

        if (purchase.PaymentPending && !purchase.TrialStillValid) {
          purchaseExpireText = Tr.Translate("language", "requires_payment");
        }

        if (new Date().getTime() - purchaseExpireDt.getTime() > 0) {
          purchaseExpireText = Tr.Translate("language", "expired");
        }

        if (subscription) {
          return (
            <div className="workgroup-select-purchase-item">
              <div className="workgroup-select-purchase-item-left">
                <strong style={{ minWidth: "10em" }}>
                  {Tr.Translate("subscription", subscription.SubscriptionName)}
                </strong>
                {!mobileCheck() && (
                  <div>
                    {` (id:${purchase.ExternalPaymentSystemPurchaseID.trim()})`}
                  </div>
                )}
              </div>
              <div className="workgroup-select-purchase-item-right">
                {purchaseExpireText}
              </div>
            </div>
          );
        }
      }
    }

    return <div className="workgroup-select-purchase-item"></div>;
  };

  const editWorkgroup = (data: any) => {
    setLoading(true);
    ApiService.WorkGroupController.UpdateWorkgroup(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage("success", Tr.Translate("language", "workgroup_edited"));
          DismissModal("addWorkGroupModal");
          loadWorkGroups();
        } else {
          if (response.raw.status == 422) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "max_workgroup_limit_for_license")
            );
          }
        }
        setLoading(false);
      }
    );
  };

  const addNewWorkgroup = (data: any) => {
    setLoading(true);

    setToEditWorkgroup(data);
    ApiService.WorkGroupController.InsertWorkgroup(
      data,
      (response: IAPIResponse) => {
        if (response.error === null) {
          ToastMessage("success", Tr.Translate("language", "workgroup_added"));
          DismissModal("addWorkGroupModal");
          loadWorkGroups();
        } else {
          if (response.raw.status == 422) {
            ToastMessageUnique(
              "error",
              Tr.Translate("language", "max_workgroup_limit_for_license")
            );
          }
        }
        setLoading(false);
      }
    );
  };

  const deleteWorkgroup = () => {
    if (toEditWorkgroup) {
      setLoading(true);
      ApiService.WorkGroupController.DeleteWorkgroup(
        toEditWorkgroup?.Id ?? 0,
        (response: IAPIResponse) => {
          if (response.error === null) {
            DismissModal("DeleteWorkgroup");
            DismissModal("addWorkGroupModal");
            loadWorkGroups();
          }
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    loadWorkGroups();
  }, []);

  const purchaseForWorkGroupIsValid = (workgroup: IWorkgroup) => {
    return workgroup.PurchaseValid || workgroup.TrialStillValid;
  };

  const signIn = (workgroup: IWorkgroup) => {
    setLoadingSignIn(true);
    ApiService.WorkGroupController.SignIntoWokgroup(
      workgroup.Id,
      (response: IAPIResponse) => {
        if (response.error === null) {
          localStorage.setItem("workgroupSessionToken", response.parsed.Token);
          dispatch(GenericActions.SetActiveWorkgroup(workgroup));
          dispatch(GenericActions.setActiveBook(undefined));
          dispatch(GenericActions.setActiveBookTimeline(undefined));
          navigate(AppRoutes.EVENT_MAP_PAGE);
        }

        setLoadingSignIn(false);
      }
    );
  };

  return (
    <div
      className="workgroup-page-wrap"
      style={{
        backgroundImage: GenerateAssetUrl(
          "/Assets/Public/book-cover.jpeg",
          true
        ),
      }}
    >
      <SmartModal
        isWideModal
        modalUniqueId="statisticsModal"
        title={Tr.Translate("language", "statistics")}
        content={<StatisticPage workgroupId={toEditWorkgroup?.Id} />}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("statisticsModal");
            },
          },
        ]}
      />
      <SmartModal
        titleBarExtra={[<FeedbackHandler section="workgroup-picker" />]}
        title={Tr.Translate(
          "language",
          editing ? "edit_workgroup" : "add_workgroup"
        )}
        modalUniqueId="addWorkGroupModal"
        content={
          <div>
            <Loadable
              isLoading={loading}
              content={
                <MultiForm
                  onSubmit={(data: any) => {
                    let data_ = {
                      ...toEditWorkgroup,
                      ...data,
                      WorkgroupImage: imgUrl,
                    };

                    if (!data_.PurchaseID || data_.PurchaseID === "0") {
                      ToastMessage(
                        "warning",
                        Tr.Translate(
                          "language",
                          "license_selection_is_required"
                        )
                      );
                      return;
                    }

                    if (data_.Id) {
                      editWorkgroup(data_);
                    } else {
                      addNewWorkgroup(data_);
                    }
                  }}
                  formUniqueId="addWorkgroupForm"
                  inputs={[
                    {
                      type: "text",
                      name: "WorkgroupName",
                      required: true,
                      currentValue: toEditWorkgroup?.WorkgroupName,
                      extraParams: { maxLength: 255 },
                      label: Tr.Translate("language", "workgroup_name"),
                    },
                    {
                      type: "text",
                      name: "WorkgroupDescription",
                      currentValue: toEditWorkgroup?.WorkgroupDescription,
                      extraParams: { maxLength: 3000 },
                      label: Tr.Translate("language", "workgroup_description"),
                      required: true,
                    },
                    {
                      type: "custom",
                      name: "_image",
                      element: (
                        <ImagePicker
                          text={Tr.Translate("language", "workgroup_logo")}
                          onLoad={setImgUrl}
                          initialImage={
                            toEditWorkgroup?.WorkgroupImage
                              ? toEditWorkgroup?.WorkgroupImage
                              : imgUrl
                          }
                        />
                      ),
                    },
                    {
                      type: "select",
                      name: "PurchaseID",
                      required: true,
                      currentValue: toEditWorkgroup?.PurchaseID,
                      label: Tr.Translate("language", "workgroup_license"),
                      extraParams: {
                        onRenderOption: (
                          item: ISelectableOption<any> | undefined
                        ) => {
                          return (
                            <div style={{ width: "100%" }}>
                              {getPurchasePublicIdentifier(item?.key)}
                            </div>
                          );
                        },
                        underElement: (
                          <div style={{ display: "flex", gap: "1em" }}>
                            <PrimaryButton
                              onClick={() => {
                                SummonModal("storeModal");
                              }}
                              text={Tr.Translate("language", "buy_license")}
                            ></PrimaryButton>
                          </div>
                        ),
                        options: [{ key: 0, text: "" }].concat(
                          (purchases ?? []).map((x: IPurchase) => {
                            return {
                              key: x.Id ?? 0,
                              text: getPurchaseShortIdentifier(x),
                            };
                          })
                        ),
                      },
                    },
                  ]}
                />
              }
            />
          </div>
        }
        leftButtons={
          editing
            ? [
                {
                  text: "",
                  iconOnlyLabel: Tr.Translate("language", "delete"),
                  disabled: loading,
                  iconName: "Trash",
                  onClick: () => {
                    setAcceptBookRemoval(false);
                    SummonModal("DeleteWorkgroup");
                  },
                },
              ]
            : []
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("addWorkGroupModal");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading,
            onClick: () => {
              triggerFormValidation("addWorkgroupForm");
            },
          },
        ]}
      />
      <SmartModal
        title={Tr.Translate("language", "delete_workgroup")}
        modalUniqueId="DeleteWorkgroup"
        content={
          <Loadable
            isLoading={loading}
            content={
              <div className="erease-workgroup-huge-warning">
                <MessageBar messageBarType={MessageBarType.warning}>
                  {Tr.Translate("language", "delete_workgroup_warning")}
                </MessageBar>
                <br />
                <Checkbox
                  checked={acceptBookRemoval}
                  onChange={(e, c) => {
                    if (c !== undefined) {
                      setAcceptBookRemoval(c);
                    }
                  }}
                  label={Tr.Translate("language", "delete_workgroup_accept")}
                />
              </div>
            }
          />
        }
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("DeleteWorkgroup");
            },
          },
          {
            text: Tr.Translate("language", "accept"),
            disabled: loading || !acceptBookRemoval,
            onClick: () => {
              deleteWorkgroup();
            },
          },
        ]}
      />
      <SmartModal
        titleBarExtra={[<FeedbackHandler section="store" />]}
        title={Tr.Translate("language", "obtain_a_license")}
        modalUniqueId="storeModal"
        isWideModal
        content={<StorePage />}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("storeModal");
            },
          },
        ]}
      />
      <SmartModal
        title={Tr.Translate("language", "renew_license_purchase")}
        modalUniqueId="renewLicenseModal"
        content={<div></div>}
        buttons={[
          {
            text: Tr.Translate("language", "cancel"),
            disabled: loading,
            onClick: () => {
              DismissModal("storerenewLicenseModalModal");
            },
          },
        ]}
      />
      <CenterPad
        content={
          <Loadable
            isLoading={loading}
            content={
              <div>
                {(window as any)["WorkgroupSessionExpired"] && (
                  <MessageBar messageBarType={MessageBarType.error}>
                    {Tr.Translate("language", "workgroup_session_expired")}
                  </MessageBar>
                )}
                <div className="workgroup-picker-main-title">
                  {Tr.Translate("language", "workgroup_main_list")}
                </div>
                {(workgroups ?? []).length === 0 && (
                  <MessageBar messageBarType={MessageBarType.info}>
                    {Tr.Translate("language", "no_workgroup_ready")}
                  </MessageBar>
                )}

                <div className="workgroup-selector-container">
                  {(workgroups ?? []).map((x: IWorkgroup, i: number) => {
                    return (
                      <div key={i} className={"workgroup-selector"}>
                        {!purchaseForWorkGroupIsValid(x) && (
                          <div>
                            <MessageBar messageBarType={MessageBarType.warning}>
                              {Tr.Translate("language", "expired_purchase")}
                            </MessageBar>
                          </div>
                        )}
                        <div className="inline-button-selector-zone">
                          <div className="workgroup-selector-image">
                            <ImageAssetLoader
                              src={
                                x.WorkgroupImage
                                  ? x.WorkgroupImage
                                  : GenerateAssetUrl(
                                      "/Assets/Public/default-wg-image.png"
                                    )
                              }
                            />
                          </div>
                          <div className="inline-button-selector-zone-labels">
                            <div className="workgroup-selector-name">
                              {x.WorkgroupName}
                            </div>
                            <div className="workgroup-selector-description">
                              {x.WorkgroupDescription}
                            </div>
                          </div>
                        </div>

                        <div className="inline-button-selector-zone-wide">
                          <div className="inline-button-selector-zone-slot">
                            <CustomTooltip
                              isButton
                              disabled={loggedUser?.Id != x.OwnerID}
                              iconName="Settings"
                              content={
                                <div>{Tr.Translate("language", "edit")}</div>
                              }
                              onClick={() => {
                                setEditing(true);
                                setToEditWorkgroup(x);
                                SummonModal("addWorkGroupModal");
                              }}
                            />
                            <CustomTooltip
                              isButton
                              disabled={loggedUser?.Id != x.OwnerID}
                              iconName="LineChart"
                              content={
                                <div>
                                  {Tr.Translate("language", "statistics")}
                                </div>
                              }
                              onClick={() => {
                                setEditing(true);
                                setToEditWorkgroup(x);
                                SummonModal("statisticsModal");
                              }}
                            />
                          </div>

                          <Loadable
                            noPadding
                            isLoading={loadingSignIn}
                            content={
                              <PrimaryButton
                                onClick={() => {
                                  signIn(x);
                                }}
                                disabled={!purchaseForWorkGroupIsValid(x)}
                                text={Tr.Translate(
                                  "language",
                                  "workgroup_open"
                                )}
                                iconProps={{ iconName: "Go" }}
                              />
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="button-add-on-right">
                  <PrimaryButton
                    onClick={() => {
                      setEditing(false);
                      setToEditWorkgroup(undefined);
                      setImgUrl("");
                      SummonModal("addWorkGroupModal");
                    }}
                    text={Tr.Translate("language", "add")}
                    iconProps={{ iconName: "AddGroup" }}
                  />
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default WorkGroupPickerPage;
